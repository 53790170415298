
import Markdown from 'marked-react';



function WebDevCoursePage() {

    return (
        <div className="page">
            <Markdown>{`
# Web Development

## Goal


Outcome: Able to create client-side web experiences in raw html/js/css


### Primary: Become proficient in the fundamentals of the web platform (html, javascript, css, http)

Particularly, we aimed to equip students with:
- a conceptual framework of how the 'web platform' works
- ability to build javascript-powered web experiences from scratch
- self confidence 
- joy/excitement

### Secondary: Help students & parents gauge interest and ability for further development

By the end of the week, students and parents should have an informed understanding of the student's software development status:
- interest
- current proficiency
- raw/natural ability

## Project

Students will create a dynamic social webapp of their own creation.  

Examples/inspiration:
  - [kahoot](https://kahoot.it?pin=8856608&refer_method=link)

## Daily Schedule
  - Morning Session (~4hrs)
    - Demo Time
    - How Thew Web Platform Works
    - Computer Science
    - Javascript/html/css topic
    - Introduction of Daily Challenge
  - Afternoon Session (~4hrs)
    - Work on your personal project
      - Apply the Daily Challenge
      - Make it awesome
      - Stretch yourself
    - Lab Assistance
  
`}</Markdown>
        </div>
    );
}

export default WebDevCoursePage;

/**/