import Markdown from 'marked-react';
import _ from "underscore"

let PersonView = (props) => {
    let { person } = props

    return <div className="person">
        <img src={person.image} />
        <h3>{person.name}</h3>
        <ul>
            {_.map(person.facts, (fact) => {
                return <li><Markdown>{fact}</Markdown></li>
            })}
        </ul>
    </div>
}

export default PersonView