import React, { useState, useEffect } from 'react';
import _ from "underscore"
import Markdown from 'marked-react';
import './CodeCamp.css';
import PersonView from './PersonView'
import CodingFun from './CodingFun'

let get = (url, onBody) => {
  function reqListener () {
    onBody(this.responseText);
  }
  
  var oReq = new XMLHttpRequest();
  oReq.addEventListener("load", reqListener);
  oReq.open("GET", url);
  oReq.send();

}


function CodeCamp() {


  let folks = [
    {
      name:"Stu Penrose",
      image:"/irlcodecamps/stu.png",
      facts:[
        "Principal Instructor", 
        "CEO, Co-Founder @ [Holler](http://holler.fyi)",
        "[stu.penrose.us](http://stu.penrose.us)",
        "[github](https://github.com/stupenrose)"]
    },{
      name:"Jacob Keller",
      image:"/irlcodecamps/jake.jpeg",
      facts:[
        "Co-Founder @ [Holler](http://holler.fyi)",
        "[LinkedIn](https://www.linkedin.com/in/jacob-keller-490b35128/)",
        "[github](https://github.com/JcbKeller)"]
    },{

      name:"James Penrose",
      image:"/irlcodecamps/james.png",
      facts:[
        "Team Lead @ [Brain Stormz Robotics](https://brainstormz.us)",
        "[brainstormz.us](https://brainstormz.us)",
        "[github](https://github.com/stheyounger)"]
    },{
      name:"Crystal Penrose",
      image:"/irlcodecamps/crystal.jpeg",
      facts:[
        "Classical Educator & CC Director",
        "Head-Coach, Thunder Chickens Robotics"]
    }
  ]

  return (
    <div className="page">
      

      <Markdown>{`
# IRL Coding Mentorship

IRL Coding Mentorship is based on [Stu Penrose's](https://stu.penrose.us/getting-started) answer to the often asked question, "How do I become a software engineer?"

This series of intensive courses is designed create a path for participants to become full-stack software engineers by the end of the summer.  Each class builds on the previous, and students are left with actionable & useful skills at each step.


## Philosophy
  - Start from first-principles
  - Balance theory with practice
  - De-mystify everything
  - Push each student individually
  - Teach in the context of 
    - a meaningful personal project
    - a collaborative, work-like environment

## Summer Intensive Curriculum 
### [Coding Fundamentals](/codingfun/) (1 week)
  - What: Build a foundation for future learning & practice in software engineering and computer science
  - Outcome: Equip students & parents to assess interest and ability in software engineering

### [Web Development](/irlcodecamps/webdev) (2 weeks)
  - What: Become proficient in the fundamentals of the web platform (html, javascript, css, http)
  - Outcome: Able to create client-side web experiences in raw html/js/css

### Backend Development (2 weeks)
   - What: Become proficient in the fundamentals of backend development (http, REST, SQL)
   - Outcome: Able to create full-stack webapps in raw html/js/css/sql

### Advanced Webapps (2 weeks)
  - What: Learn industry-standard frameworks, tools & practices.
  - Outcome: Be ready to start work as an entry-level software engineer on an existing node/js webapp project.

## Next Steps

### [Join our Holler Community](https://holler.fyi/s/public540)
  - Ask questions
  - Learn about Course Updates and Availability 
  - Stay connected with the broader IRL coding community (other cohorts, lab assistants, instructors)
### [Register for a Course](https://www.holler.fyi/u/irlcodecamps)



## Instructors & Lab Assistants
`}</Markdown>

{_.map(folks, (person)=> <PersonView  person = {person}/>)}

<Markdown>{`


`}</Markdown>
       </div>
  );
}

export default CodeCamp;
