
import Markdown from 'marked-react';



function WebDevClass1() {

    return (
        <div className="page">
            <Markdown>{`
  # Class Apps
  - https://stupenrose.github.io/2022-07-18-web-development-blankslate-demo/
  - https://stheyounger.github.io/shipProject/
  - https://teagueshepard.github.io/place-holder/
  - https://cuzko-o.github.io/Web/Index.HTML
  - https://cuzko-o.github.io/Web
  - https://blueberrylem.github.io/web-coding/
  - https://dockingbay-94.github.io/qwerty/
  - https://hotpinktangerine.github.io/classStuff/
  - https://browardo.github.io/classsProject/
  - https://tc-penrose.github.io/projects/
  - https://judge7567.github.io/Beepboop/
`}</Markdown>
        </div>
    );
}

export default WebDevClass1;
