import React, { useState, useEffect } from 'react';
// import _ from "underscore"
import Markdown from 'marked-react';



function CodingFun() {

    return (
        <div className="page">
            <Markdown>{`
# Coding Fundamentals

## Goal

### Primary: Provide a foundation for future (self-directed) learning & practice in software engineering and computer science.

Particularly, we aimed to equip students with:
- a conceptual framework of how computers & code works
- self confidence 
- joy/excitement

### Secondary: Help students & parents gauge interest and ability for further development

By the end of the week, students and parents should have an informed understanding of the student's software development status:
- interest
- current proficiency
- raw/natural ability

## Daily Schedule
  - Morning Session (~4hrs)
    - Demo Time
    - How Computers Work
    - Computer Science
    - MakeCode/Javascript Topic
    - Introduction of Daily Challenge
  - Afternoon Session (~4hrs)
    - Work on your personal project
      - Apply the Daily Challenge
      - Make it awesome
      - Stretch yourself
    - Lab Assistance
  
`}</Markdown>
        </div>
    );
}

export default CodingFun;

/**/