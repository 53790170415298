import './App.css'
import React from 'react'
import CodeCamp from './CodeCamp'
import CodingFun from './CodingFun'
import WebDevCoursePage from './WebDevCoursePage'
import WebDevClass1 from './WebDevClass1'

console.log("Hi there!")

function App() {
  let path = window.location.pathname

  let content;
  if(path === "/classes/webdev1"){
    content = <WebDevClass1/>
  }else if(path.indexOf("/irlcodecamps/webdev") == 0){
    content = <WebDevCoursePage/>
  }else if(path.indexOf("/irlcodecamps") == 0){
    content = <CodeCamp/>
  }else if(path.indexOf("/codingfun") == 0){
      content = <CodingFun/>
  }else{
    content = <div style={{"text-align": "center", "font-family": "sans-serif", margin:"10%"}}>
      <h1>There are a lot of Penroses out there.  But, maybe you're interested in <a href="http://stu.penrose.us">Stu Penrose</a>?</h1>
    </div>
  }

  return [content]
}

export default App;
